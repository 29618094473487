import React from "react";
import { useState } from "react";

function Technologies(props) {
  const { technologiesList } = props;
  const [selectedTab, setSelectedTab] = useState(technologiesList[0].title);
  return (
    <div className="technologies_wrapper">
      <h1 className="title text-dark text-center mt-3">Technology Stack</h1>
      <div className="tabs mt-5">
        {technologiesList.map((list) => {
          return (
            <div className={selectedTab === list.title ? "tablist activeTab" : "tablist"} onClick={() => setSelectedTab(list.title)}>
              {list.title}
            </div>
          );
        })}
      </div>
      <div className="tabsContent mt-5">
        {technologiesList
          .filter((item) => item.title === selectedTab)
          .map((item) => {
            return (
              <div className="d-flex flex-wrap gap-4 justify-content-center">
                {item.technologies.map((item) => {
                  if (item.logo == null) {
                    return (
                      <div className="d-flex align-items-center box gap-2">
                        <p className="m-0">{item.name}</p>
                      </div>
                    );
                  }
                  return (
                    <div className="d-flex align-items-center box gap-2">
                      <div className="image_wrap">
                        <img src={item.logo} alt="Logo" />
                      </div>
                      <p className="m-0">{item.name}</p>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Technologies;
