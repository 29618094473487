import React from 'react';
import "../CompanyCarousels/CompanyCarousels.scss";
import Slider from 'react-slick';

function CompanyCarousels(props) {

    const imageLogo = [
        {
            image: "/images/companyLogos/one.jpeg" 
        },
        {
            image: "/images/companyLogos/two.png" 
        },
        {
            image: "/images/companyLogos/three.png" 
        },
        {
            image: "/images/companyLogos/four.png" 
        },
        {
            image: "/images/companyLogos/five.png" 
        },
        {
            image: "/images/companyLogos/six.png" 
        },
        {
            image: "/images/companyLogos/seven.png" 
        },
        {
            image: "/images/companyLogos/eight.png" 
        },
        {
            image: "/images/companyLogos/nine.png" 
        },
        {
            image: "/images/companyLogos/ten.png" 
        },
    ]

    var slideSettings = {
        infinite: true,
        speed: 1000,
        autoplaySpeed: 2500,
        autoplay: true,
        slidesToShow: 6,
        slidesToScroll:1,
        // variableWidth: true,
        centerMode: true,
        arrows: false,
        dots: true,
        swipeToSlide: false,
        draggable: false,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
                variableWidth: false
              }
            }
        ]
    }
    return (
        <div className='Company_Wrapper'>
            <h3>Our Story</h3>
            <p>List of brands with whom we worked since long time and given many fantastic works to them</p>
            <Slider {...slideSettings} className="slick-dot-nav">
                {imageLogo.map((data, index) => (
                    <div className='col-xl-2'>
                <div className='slide_block' key={index}>
                    <img src={data.image}/>
                </div>
                </div>
            ))}
                </Slider> 
        </div>
    );
}

export default CompanyCarousels;