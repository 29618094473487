import React from "react";
import { Link } from "react-router-dom";
import { slugify } from "../../../utils";

const PropOne = ({ projectStyle, portfolio }) => {
  return (
    <>
      <div
        className={`project-grid ${projectStyle}`}
      >
        <div className="thumbnail custom_project_box">
          {/* <Link to={process.env.PUBLIC_URL + `/project-details/${slugify(portfolio.title)}`}> */}
          <img src={portfolio.image} alt="icon" />
          {/* </Link> */}
        </div>
        {/* <div className="content">
          <h4 className="title"> 
					<Link to={process.env.PUBLIC_URL + `/project-details/${slugify(portfolio.title)}`}>{portfolio.title}</Link>
				</h4>
				<span className="subtitle">
					{portfolio.category.map((cat, i) => (
						<span key={i}>{cat}</span>
					))}
				</span>
        </div> */}
        <div className="content">
          <div className="subtitle" style={{fontSize: "20px", color: "#D3D3D4"}}>
            {portfolio.title}
          </div>
        </div>
      </div>
    </>
  );
};

export default PropOne;
