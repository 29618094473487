import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import AboutOne from "../component/about/AboutOne";
import BannerOne from "../component/banner/BannerOne";
import BlogOne from "../component/blog/BlogOne";
import BrandOne from "../component/brand/BrandOne";
import CounterUpOne from "../component/counterup/CounterUpOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import PricingOne from "../component/pricing/PricingOne";
import ProjectOne from "../component/project/ProjectOne";
import ServicePropOne from "../component/service/ServicePropOne";
import TestimonialOne from "../component/testimonial/TestimonialOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SplashData from "../data/splash/SplashData.json";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Timeline from "../component/Timeline/Timeline";
import Campaign from "../component/Campaign/Campaign";
import CompanyCarousels from "../component/CompanyCarousels/CompanyCarousels";
import CampaignTestimonialOne from "../component/campaignTestimonial/CampaignTestimonialOne";

const DigitalAgency = () => {
  const FeatureData = SplashData[1];

  return (
    <>
      <SEO title="Home" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BannerOne />
        <div className="section section-padding-2 mine_services">
          <div className="container">
            <SectionTitle
              subtitle="Our Services"
              title=" What we are <span>good</span> at?"
              description="Our goal is to create tailor-made solutions to your specific needs utilizing cutting-edge and innovative technologies that drive your business growth. We aim to keep ourselves familiar with emerging technologies to help you stay ahead of the competition and adapt to the ever-changing market demands."
              textAlignment="heading-light-left"
              textColor=""
            />
            <div className="row">
              <ServicePropOne
                colSize="col-xl-4 col-md-6"
                serviceStyle=""
                itemShow="3"
                marginTop="no"
              />
            </div>
          </div>
          <ul className="list-unstyled shape-group-10">
            {/* <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-9.png"}
                alt="Circle"
              />
            </li> */}
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"}
                alt="Circle"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>

        <div
          className="section section-padding splash-features"
          id="splsh-features"
        >
          <div className="container">
            <div className="section-heading heading-light-left">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <h6 className="subheading"> Why Choose Us?</h6>
                  <h2 className="span_title"> How will you <span>benefit</span>?</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {FeatureData.slice(0, 6).map((data, index) => (
                <div className="col-xl-4 col-md-6" key={data.id}>
                  <AnimationOnScroll
                    animateIn="fadeInLeft"
                    // animateOnce={true}
                    delay={(100 * index) / 0.9}
                  >
                    <div className="services-grid digital_wrap">
                      <div className="thumbnail custom_thumbnail">
                        <img
                          src={process.env.PUBLIC_URL + data.icon}
                          alt="icon"
                        />
                      </div>
                      <div className="content">
                        <h5
                          className="title_us"
                          dangerouslySetInnerHTML={{ __html: data.title }}
                        ></h5>
                        {/* <p>{data.para}</p> */}
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
              ))}
            </div>
          </div>
          <ul className="list-unstyled shape-group-10">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-1.png"}
                alt="Circle"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-3.png"}
                alt="Circle"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>
        <Campaign />
        {/* <AboutOne /> */}
        {/* <ProjectOne /> */}
        <Timeline />
        <CompanyCarousels />
        <CampaignTestimonialOne />
        {/* <TestimonialOne /> */}
        <CounterUpOne />
        {/* <div className="section bg-color-light section-padding">
                <div className="container">
                    <SectionTitle 
                        subtitle="Pricing Plan"
                        title="We’ve built solutions for..."
                        description="Flexible pricing options for freelancers
                        and design teams."
                        textAlignment=""
                        textColor=""
                    />
                    <PricingOne />
                </div>
                <ul className="list-unstyled shape-group-3">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"} alt="shape" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"} alt="shape" /></li>
                </ul>
            </div> */}
        {/* <BrandOne /> */}
        {/* <BlogOne /> */}
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default DigitalAgency;
