import React, {useRef} from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BannerOne from "../component/banner/BannerOne";
import CounterUpOne from "../component/counterup/CounterUpOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ServicePropOne from "../component/service/ServicePropOne";
import TestimonialOne from "../component/testimonial/TestimonialOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SplashData from "../data/splash/SplashData.json";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Timeline from "../component/Timeline/Timeline";
import Campaign from "../component/Campaign/Campaign";
import CompanyCarousels from "../component/CompanyCarousels/CompanyCarousels";
import BannerCampaign from "../common/header/BannerCampaign";
import { Link } from "react-router-dom";
import FaqOne from "../component/faq/FaqOne";
import CampaignFormPage from "./CampaignFormPage";
import CampaignCounterUpOne from "../component/campaignCounterup/CampaignCounterUpOne";
import CampaignTestimonialOne from "../component/campaignTestimonial/CampaignTestimonialOne";
import Whyarewe from "../component/Whyarewe/Whyarewe";

const CampaignPage = () => {
  const FeatureData = SplashData[1];
  const formRef = useRef(null);

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  const dataList = [
    {
      nameone: "Striking visual appeal",
      imageone: "/images/vectoricons/visual_appeal.png"
    },
    {
      nameone: "Optimized for seamless mobile browsing",
      imageone: "/images/vectoricons/mobile_browsing.png"

    },
    {
      nameone: "SEO friendly",
      imageone: "/images/vectoricons/seo.png"

    },
    {
      nameone: "Lightning-fast loading times",
      imageone: "/images/vectoricons/high-speed.png"

    },
    {
      nameone: "Advanced security protocols",
      imageone: "/images/vectoricons/secure-data.png"

    },
  ];

  return (
    <>
      <SEO title="Campaign" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BannerCampaign scrollToForm={scrollToForm} />
        <Campaign scrollToForm={scrollToForm} />
        <div
          className="section section-padding how_wrapper"
          id="splsh-features"
        >
          <div className="container">
            <div className="section-heading heading-light-left">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <h2 className="title text-center w-100">How can we help</h2>
                </div>
                <div className="col-lg-12 col-md-12">
                  <p className="text-center w-100">
                    We will deliver a professional website that you will love,
                    whether it's for your online business, showcasing your work
                    or hobbies, promoting a social awareness campaign,
                    celebrating a special event, presenting academic work, or
                    testing a new idea you have.
                  </p>
                </div>
              </div>
            </div>
            <div className="campaign_service_wrap d-flex flex-wrap justify-content-center gap-4">
              {dataList.map((data, index) => (
                <div key={data.id}>
                  <AnimationOnScroll
                    animateIn="fadeInLeft"
                    // animateOnce={true}
                    delay={(100 * index) / 0.9}
                  >
                    <div className="services-grid digital_wrap px-5">
                      <div className="custom_thumbnail">
                        <img
                          src={process.env.PUBLIC_URL + data.imageone}
                          alt="icon"
                        />
                      </div>
                      <div className="content">
                        <h5
                          className="title_us pt-4"
                          //   dangerouslySetInnerHTML={{ __html: data.title }}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {data.nameone}
                        </h5>
                        {/* <p>{data.para}</p> */}
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
              ))}
            </div>
          </div>
          <ul className="list-unstyled shape-group-10">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-1.png"}
                alt="Circle"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-3.png"}
                alt="Circle"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>
        <Whyarewe/>
        {/* <div className="section section-padding-2 bg-color-dark">
          <div className="container">
            <div className="row">
                <div className="col-lg-6 d-flex justify-content-center align-item-center">
                    <img src="images/laptopman.png" style={{transform: "scale(0.6)"}}/>
                </div>
              <div className="col-lg-6">
                <SectionTitle
                  subtitle="Why your business needs a website"
                  title="Start your business/entrepreneurship <br/> journey with us"
                  description="We deliver tailored solutions to meet the unique needs of businesses across various Industries. With our expert team, we ensure high-quality and timely delivery of projects. We take pride in our in-depth knowledge of different industries and our deep understanding of the latest technologies."
                  textAlignment="heading-light-left"
                  textColor=""
                />
                <p className="mb-3">
                  Do you have an amazing idea for a new online business but
                  worry about agencies charging you thousands of $ for the
                  website?
                </p>
                <p className="mb-3">
                  What if we told you that you can start your online business
                  journey today in 2 minutes without spending a single penny?
                </p>
                <p className="mb-3">
                  You could save a lot of money and jumpstart your business
                  today with Nyeon’s |campaign name|. We are providing FREE
                  WordPress websites to a few selected individuals to grow their
                  digital presence.
                </p>
                <p className="mb-3">
                  So don’t miss out on this opportunity, Let’s start your dream
                  business Today with Nyeon’s |campaign name|. Hurry up, limited
                  slots are available for our Exclusive Campaign.
                </p>
                <Link
                  to={process.env.PUBLIC_URL + "/contact"}
                  className="axil-btn btn-fill-primary btn-large mt-4"
                >
                  Apply in 2 minutes
                </Link>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-10">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-9.png"}
                alt="Circle"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"}
                alt="Circle"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div> */}
        {/* <AboutOne /> */}
        {/* <ProjectOne /> */}
        <CampaignTestimonialOne />
        <CampaignCounterUpOne scrollToForm={scrollToForm} />
        <FaqOne />
        <div ref={formRef}>
          <CampaignFormPage />
        </div>
        {/* <div className="section bg-color-light section-padding">
                <div className="container">
                    <SectionTitle 
                        subtitle="Pricing Plan"
                        title="We’ve built solutions for..."
                        description="Flexible pricing options for freelancers
                        and design teams."
                        textAlignment=""
                        textColor=""
                    />
                    <PricingOne />
                </div>
                <ul className="list-unstyled shape-group-3">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"} alt="shape" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"} alt="shape" /></li>
                </ul>
            </div> */}
        {/* <BrandOne /> */}
        {/* <BlogOne /> */}
        
        {/* <CtaLayoutOne /> */}
        
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default CampaignPage;
