// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Company_Wrapper {
  background: var(--color-blackie);
  padding: 120px 0; }
  .Company_Wrapper h3 {
    text-align: center;
    margin-bottom: 12px; }
  .Company_Wrapper p {
    text-align: center; }
  .Company_Wrapper .slick-dots {
    margin-top: 40px !important;
    display: flex !important;
    justify-content: center; }
    .Company_Wrapper .slick-dots li {
      display: inline-block; }
      .Company_Wrapper .slick-dots li button {
        font-size: 0;
        padding: 0; }
`, "",{"version":3,"sources":["webpack://./src/component/CompanyCarousels/CompanyCarousels.scss"],"names":[],"mappings":"AAAA;EACI,gCAAgC;EAChC,gBAAgB,EAAA;EAFpB;IAIQ,kBAAkB;IAClB,mBAAmB,EAAA;EAL3B;IAQQ,kBAAkB,EAAA;EAR1B;IAWQ,2BAA2B;IAC3B,wBAAwB;IACxB,uBAAuB,EAAA;IAb/B;MAeY,qBAAqB,EAAA;MAfjC;QAiBgB,YAAY;QACZ,UAAU,EAAA","sourcesContent":[".Company_Wrapper {\n    background: var(--color-blackie);\n    padding: 120px 0;  \n    h3 {\n        text-align: center;\n        margin-bottom: 12px;\n    } \n    p{\n        text-align: center;\n    }\n    .slick-dots {\n        margin-top: 40px !important;\n        display: flex !important;\n        justify-content: center;\n        li {\n            display: inline-block;\n            button {\n                font-size: 0;\n                padding: 0;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
