import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import CounterUp from '../../component/counterup/CounterUp';


const CounterUpOne = () => {
    return (
        <div className="section section-padding counter_wrapper">
            <div className="container">
            {/* <SectionTitle 
                    subtitle="Along the Way"
                    title="Our Success"
                    description=""
                    textAlignment="heading-light"
                    textColor=""
                /> */}
            <div className="section-heading heading-light">
                <div className="subtitle w-100" dangerouslySetInnerHTML={{__html: "Along the Way"}}></div>
                <h3 className="title span_title w-100 text-light" dangerouslySetInnerHTML={{__html: "Our Success"}}></h3>
                <p className='w-100' dangerouslySetInnerHTML={{__html: ""}}></p>
            </div>
                <div className="row">
                    <CounterUp colSize="col-lg-4 col-12" layoutStyle="" evenTopMargin="" />
                </div>
            </div>
        </div>
    )
}


export default CounterUpOne;