import React from "react";
import TestimonialData from "../../data/testimonial/TestimonialData.json";
import Slider from "react-slick";
import { useState } from "react";


const ProjectSliderOne = ({ colSize, itemShow }) => {
  const allImages = [
    {
      imagename: "https://99designs-blog.imgix.net/blog/wp-content/uploads/2018/10/attachment_100040756-e1538485934255.jpeg?auto=format&q=60&fit=max&w=930"
    },
    {
      imagename: "https://99designs-blog.imgix.net/blog/wp-content/uploads/2018/10/attachment_100040756-e1538485934255.jpeg?auto=format&q=60&fit=max&w=930"
    },
    {
      imagename: "https://99designs-blog.imgix.net/blog/wp-content/uploads/2018/10/attachment_100040756-e1538485934255.jpeg?auto=format&q=60&fit=max&w=930"
    },
    {
      imagename: "https://99designs-blog.imgix.net/blog/wp-content/uploads/2018/10/attachment_100040756-e1538485934255.jpeg?auto=format&q=60&fit=max&w=930"
    },
    {
      imagename: "https://99designs-blog.imgix.net/blog/wp-content/uploads/2018/10/attachment_100040756-e1538485934255.jpeg?auto=format&q=60&fit=max&w=930"
    }
  ]
  const [videoModal, setVideoModal] = useState(false);
  var slideSettings = {
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // variableWidth: true,
    centerMode: true,
    arrows: false,
    dots: true,
    swipeToSlide: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };

  const handleVideoModal = () => {
    setVideoModal(!videoModal);
  };

  return (
    <>
      <div className="demo-slider">
        <Slider {...slideSettings} className="slick-dot-nav">
          {allImages.map((data, index) => (
            <div className="slide_block" key={index}>
              <div className="testimonial-grid">
                {/* <span className="social-media"> */}
                {/* </span> */}
                {/* <p className="line_description">{data.description}</p> */}
                <div className="author-info pb-4">
                  <div className="">
                    <img
                      src={process.env.PUBLIC_URL + data.imagename}
                      alt="Google Review"
                    />
                  </div>
                  {/* <div className="content">
                    <span className="name">{data.authorname}</span>
                    <span className="designation">{data.authordesig}</span>
                  </div> */}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {videoModal && (
        <div className="video_box">
          <div className="video_wrapper_box">
            <div className="header"></div>
            <div className="content"></div>
            <div className="footer"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectSliderOne;
