import React from "react";
import TestimonialData from "../../data/testimonial/TestimonialData.json";
import Slider from "react-slick";
import { useState } from "react";

const allData = TestimonialData;

const CampaignTestimonialPropOne = ({ colSize, itemShow }) => {
  const [videoModal, setVideoModal] = useState(false);
  const [selectedTestimonial, setSeletedTestimonial] = useState([]);
  var slideSettings = {
    infinite: true,
    speed: 1200,
    autoplaySpeed: 3000,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // variableWidth: true,
    centerMode: true,
    arrows: false,
    dots: true,
    swipeToSlide: true,
    draggable: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };

  // const handleVideoModal = () => {
  // };

  const handleSelection = (data) => {
    setSeletedTestimonial(data);
    setVideoModal(!videoModal);
  };

  return (
    <>
      <div className="demo-slider">
        <Slider {...slideSettings} className="slick-dot-nav">
          {allData.map((data, index) => (
            <div
              className="slide_block"
              key={index}
              onClick={() => handleSelection(data)}
            >
              <div className="testimonial-grid">
                {/* <span className="social-media"> */}
                {/* </span> */}
                  <img src={process.env.PUBLIC_URL + data.VideoBanner } style={{display : "block", width: "60%", marginLeft: "auto", marginRight: "auto" }}></img>
                <p className="line_description">{data.description}</p>
                <div className="author-info pb-4">
                  <div className="thumb">
                    <img
                      src={process.env.PUBLIC_URL + data.authorimg}
                      alt="Google Review"
                    />
                  </div>
                  <div className="content">
                    <span className="name">{data.authorname}</span>
                    {/* <span className="designation">{data.authordesig}</span> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {videoModal && (
        <div className="video_box">
          <div className="video_wrapper_box_wrap">
          <div className="header d-flex justify-content-between pt-4">
                <div className="bg-light d-flex align-items-center justify-content-center" style={{height: "50px", width: "50px", borderRadius: "50%", cursor: "pointer", border: "1px solid"}}>
                  <h4
                    className="mb-0 text-dark"
                    onClick={() => setVideoModal(false)}
                  >
                    X
                  </h4>
                </div>
              </div>
              <video id="background-video" controls autoplay playsInline loop>
              <source src={process.env.PUBLIC_URL + selectedTestimonial.videoPath} type="video/mp4" />
              </video>
            <div className="content pt-2">
              {selectedTestimonial.description}
              <div className=" d-flex mt-4">
                {/* <div className="author-info pb-4"> */}
                <div className="thumb">
                  <img
                    src={process.env.PUBLIC_URL + selectedTestimonial.authorimg}
                    alt="Google Review"
                  />
                </div>
                <div className="content d-flex p-2 align-items-center">
                  <span className="name">{selectedTestimonial.authorname}</span>
                  {/* <span className="designation">{data.authordesig}</span> */}
                </div>
                {/* </div> */}
              </div>
            </div>
            <div className="footer"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default CampaignTestimonialPropOne;
