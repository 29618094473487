import React from "react";
import "../Timeline/Timeline.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";

function AITimeline(props) {
  return (
    <section class="design-section">
      <h6>Our Approach</h6>
      <h3>How do we work?</h3>
      {/* <p>
        We work with agility and efficiency to deliver superior quality software
        solutions to clients on time, <br /> leveraging cutting-edge technology
        and innovative problem-solving skills.
      </p> */}
      <div class="timeline">
        <div class="timeline-empty"></div>
        <div class="timeline-middle">
          <div class="timeline-circle"></div>
        </div>
        <AnimationOnScroll
          animateIn="fadeInRight"
          // animateOnce={true}
          delay={0}
        >
          <div class="timeline-component timeline-content">
            <div className="timeline_image">
              <img src="/images/requirements_gathering.png" alt="UR" />
            </div>
            <h3>Requirements Gathering and Proposal</h3>
            <p>
              We engage with you to thoroughly understand your problem, project
              scope, timeline, and available resources. Based on these insights,
              we develop a detailed plan by conducting thorough research to
              explore the latest AI models and techniques available. Our
              solutions are focused on providing precise AI model details to
              tackle the problem effectively.
            </p>
            {/* <ul className="pt-4">
              <li>
                Understand problem trying to solve, the solution’s scope,
                timeline and resources available
              </li>
              <li>
                Conduct a thorough research to explore related AI models and
                techniques available
              </li>
              <li>
                Prepare a comprehensive plan on how to solve the problem using
                AI models
              </li>
            </ul> */}
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll
          animateIn="fadeInLeft"
          // animateOnce={true}
          delay={0}
        >
          <div class="timeline-component timeline-content">
            <div className="timeline_image">
              <img src="/images/MVP_Delivery.png" alt="UR" />
            </div>
            <h3>Delevering MVP</h3>
            <p>
              We first focus on building MVP to solve the core problem as
              outlined in the earlier plan. Our approach entails utilizing
              pre-trained models, fine-tuning existing ones, or developing
              custom models trained on data. The AI model's effectiveness is
              validated with detailed technical proofs.
            </p>
            {/* <ul className="pt-4">
              <li>Develop a MVP solution solve the core problem</li>
              <li>
                By utilizing pre-trained models, fine-tuning existing ones, or
                developing custom models trained on data
              </li>
              <li>
                MVP solution’s effectiveness is validated with detailed
                technical proofs
              </li>
            </ul> */}
          </div>
        </AnimationOnScroll>
        <div class="timeline-middle">
          <div class="timeline-circle"></div>
        </div>
        <div class="timeline-empty"></div>

        <div class="timeline-empty"></div>

        <div class="timeline-middle">
          <div class="timeline-circle"></div>
        </div>
        <AnimationOnScroll
          animateIn="fadeInRight"
          // animateOnce={true}
          delay={0}
        >
          <div class=" timeline-component timeline-content">
            <div className="timeline_image">
              <img src="/images/delivering_full_fledged_solution.png" alt="UR" />
            </div>
            <h3>Delivering Full-Fledged solution</h3>
            <p>
              We refine the MVP into a production-ready AI solution by
              addressing overlooked challenges, ensuring generalization,
              optimizing predictions for outlier cases, and continuously improve
              model accuracy. Client feedback is actively incorporated for
              further enhancements.
            </p>
            {/* <ul className="pt-4">
              <li>Refinement of MVP into production ready AI solution</li>
              <li>By addressing overlooked challenges in previous phase</li>
              <li>
                Ensuring Generalization and optimizing predictions for outlier
                cases
              </li>
              <li>Continuously improving model accuracy and</li>
              <li>Incorporating your feedback into the MVP</li>
            </ul> */}
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll
          animateIn="fadeInLeft"
          // animateOnce={true}
          delay={0}
        >
          <div class="timeline-component timeline-content">
            <div className="timeline_image">
              <img src="/images/performance_optimization.png" alt="UR" />
            </div>
            <h3>Performance Optimization</h3>
            <p>
              We focus on delivering performance optimized model to save you
              time and compute cost for predictions. We utilize various
              techniques like quantization, pruning, libraries and GPU based
              optimizations to decrease the inference latency and hardware
              requirements.
            </p>
          </div>
        </AnimationOnScroll>
        <div class="timeline-middle">
          <div class="timeline-circle"></div>
        </div>
        <div class="timeline-empty"></div>

        <div class="timeline-empty"></div>
        <div class="timeline-middle">
          <div class="timeline-circle"></div>
        </div>
        <div class="timeline-empty"></div>

        <div class="timeline-empty"></div>
        <div class="timeline-middle">
          <div class="timeline-circle"></div>
        </div>
        <AnimationOnScroll
          animateIn="fadeInRight"
          // animateOnce={true}
          delay={0}
        >
          <div class="timeline-component timeline-content">
            <div className="timeline_image">
              <img src="/images/model_deployment.png" alt="UR" />
            </div>
            <h3>Model Deployment</h3>
            <p>
              We don’t stop at developing the AI solution; instead, we assist
              you in creating the infrastructure for AI or integrating AI into
              your existing setup. From developing custom AI-centric frontends
              to building scalable backends, we ensure seamless handling of
              increasing demands while maintaining system stability.
            </p>
          </div>
        </AnimationOnScroll>
        <div class="timeline-empty"></div>
      </div>
    </section>
  );
}

export default AITimeline;
