import React from "react";
import Tilty from "react-tilty";

const BcrumbBannerOne = ({
  title,
  paragraph,
  subtitle,
  styleClass,
  mainThumb,
}) => {
  return (
    <div className="breadcrum-area breadcrumb-banner">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-lg-7">
            <div className="section-heading heading-left">
              <h1
                className="title h2"
                dangerouslySetInnerHTML={{ __html: title }}
              ></h1>
              <h6 className="subtitle_list" dangerouslySetInnerHTML={{ __html: subtitle }}></h6>
              <p dangerouslySetInnerHTML={{ __html: paragraph }}></p>
            </div>
          </div>
          <div className="col-lg-5">
            <img src={process.env.PUBLIC_URL + mainThumb}  alt="Illustration" style={{transform: "scale(1.3)"}} />
          </div>
        </div>
        {/* <div className={`banner-thumbnail ${styleClass}`}>
          <Tilty perspective={2000} reset={false}>
          </Tilty>
        </div> */}
      </div>
      <ul className="shape-group-8 list-unstyled">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-9.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-21.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
            alt="Line"
          />
        </li>
      </ul>
    </div>
  );
};

export default BcrumbBannerOne;
