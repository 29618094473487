import React from "react";
import TestimonialData from "../../data/testimonial/TestimonialData.json";
import Slider from "react-slick";
import { useState } from "react";

const allData = TestimonialData;

const TestimonialItem = ({ colSize, itemShow }) => {
  const [videoModal, setVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState([]);
  var slideSettings = {
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // variableWidth: true,
    centerMode: true,
    arrows: false,
    dots: true,
    swipeToSlide: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };

  const handleVideoModal = (data, index) => {
    setSelectedVideo(data);
    setVideoModal(true);
  };

  return (
    <>
      <div className="demo-slider">
        <Slider {...slideSettings} className="slick-dot-nav">
          {allData.map((data, index) => (
            <>
              <div className="slide_block" key={index}>
                <div className="testimonial-grid">
                  {/* <span className="social-media"> */}
                  <div
                    className="video_wrapper"
                    onClick={(e) => handleVideoModal(data, index)}
                  >
                    <img
                      src="https://www.allprodad.com/wp-content/uploads/2021/03/05-12-21-happy-people.jpg"
                      className="person"
                      alt="Imagebox"
                    />
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/189/189638.png"
                      className="iconic"
                      alt="play"
                    />
                    {/* <video id="background-video" autoPlay muted loop><source src="/video/testimonial.mp4" type="video/mp4" /></video> */}
                  </div>
                  {/* </span> */}
                  <p className="line_description">{data.description}</p>
                  <div className="author-info">
                    <div className="thumb">
                      <img
                        src={process.env.PUBLIC_URL + data.authorimg}
                        alt="Google Review"
                      />
                    </div>
                    <div className="content">
                      <span className="name">{data.authorname}</span>
                      {/* <span className="designation">{data.authordesig}</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </Slider>
        {videoModal && (
          <div className="video_box">
            <div className="video_wrapper_box">
              <div className="header d-flex justify-content-between">
                <div className="bg-light d-flex align-items-center justify-content-center" style={{height: "50px", width: "50px", borderRadius: "50%", cursor: "pointer", border: "1px solid"}}>
                  <h4
                    className="mb-0 text-dark"
                    onClick={() => setVideoModal(false)}
                  >
                    X
                  </h4>
                </div>
              </div>
              <div className="content">
                <video id="background-video" controls loop>
                  <source src="/video/Connections.mp4" type="video/mp4" />
                </video>
              </div>
              <div className="footer">
                <p className="mb-1">{selectedVideo.description}</p>
                <h6 className="text-dark mb-0">{selectedVideo.authorname}</h6>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TestimonialItem;
