import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";
import PhoneInput from "react-phone-input-2";
import { Dropdown, DropdownButton, Toast } from "react-bootstrap";
import axios from "axios";
import { isValidPhoneNumber } from "libphonenumber-js";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your Message has been successfully sent.
    </Alert>
  );
};

const FormOne = () => {
  const form = useRef();

  const [result, showresult] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [error, setError] = useState("");
  const [phone, setPhone] = useState("");
  const [valueone, setValueone] = useState("");
  const [valuetwo, setValuetwo] = useState("");
  const [valuethree, setValuethree] = useState("");
  const [stateData, setStateData] = useState({});
  const [indexService, setIndexService] = useState();
  const [thankyou, setThankyou] = useState(false);
  const [success, setSuccess] = useState(false);

  const bookFreeAppointment = "Book a free call with Calendly";

  const calendlyLink = "https://calendly.com/nyeontechnologies/30min";

  const handletoSaveData = (e) => {
    setStateData({ ...stateData, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // emailjs
    //   .sendForm(
    //     "service_yj5dgzp",
    //     "template_hfduayo",
    //     form.current,
    //     "WLENsTkBytC0yvItS"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
    form.current.reset();
    showresult(true);
  };

  const onPhoneChange = (data, formattedValue) => {
    setPhone(formattedValue);
    setCountryCode(data.countryCode);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  const handleSelect = (e) => {
    setValueone(e);
  };
  const handleSelecttwo = (e) => {
    setValuetwo(e);
  };
  const handleSelectthree = (e) => {
    setValuethree(e);
  };
  const ScheduleFreeAppointment = () => {
    window.open(calendlyLink);
  };

  const serviceList = [
    "Website Development",
    "Mobile Development",
    "AI/ML Development",
  ];

  const data = {
    name: stateData.name,
    email: stateData.email,
    phone_number: phone,
    service_id: indexService + 1 || 1,
    start_date: valuetwo || "Nothing",
    budget: valuethree || "Nothing",
    project_brief: stateData.project_brief || "Nothing",
  };

  //   {
  //     "name": "Bhargav Joshi",
  //     "email": "bsj@gmail.com",
  //     "phone_number": "9112345 67891",
  //     "service_id": 1,
  //     "start_date": "2023-05-01",
  //     "budget": "10000 USD",
  //     "project_brief": "This is a big project"
  // }

  const bodyFormData = new FormData();
  bodyFormData.append("data", JSON.stringify(data));
  // bodyFormData.append("file", "");

  // console.log("Tester", payload, valueone);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!isValidPhoneNumber(phone, countryCode)) {
      setError("Please Enter A Valid Phone Number");
      // Toast.error(`Please Enter a valid phone number`);
      return;
    }

    console.log("Viabhav log: ", bodyFormData.get("data"));
    if (bodyFormData.get("data").project_brief == null) {
      bodyFormData.set("data", JSON.stringify({ ...data, project_brief: "" }));
    }

    await axios
      .post("https://api.nyeon.in/client/contact-us", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log("Doddhi", res)
        setThankyou(true);
        if (res.status === 201) {
          setSuccess(true);
        } else {
          console.log("Error");
        }
      })
      .catch((err) => {
        setThankyou(true);
        setSuccess(false);
        console.log(err);
      });
  };

  return (
    <div className="formone_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-7" style={{position: "relative"}}>
            <div class="button-container">
            {thankyou && (
              <div className="thankyou_wrap">
                {success ? (
                  <>
                    <h3>Thank You!</h3>
                    <h6>
                      We have recieved your message. Someone from our team will
                      contact you soon.
                    </h6>
                  </>
                ) : (
                  <>
                    <h3>OOPS! Something Went Wrong</h3>
                    <h6>
                      Try to contact us with <br /> email : contact@nyeon.in{" "}
                      <br /> call us on : +918073917014
                    </h6>
                  </>
                )}
              </div>
            )}
            {/* <button
              className="calendly_wrapper"
              type="button"
              onClick={ScheduleFreeAppointment}
            > */}
              <div className="form-group mt-4 d-flex justify-content-center">
              <button
                type="button"
                className="axil-btn btn-fill-primary btn-primary"
                onClick={ScheduleFreeAppointment}
              >
                {bookFreeAppointment}
              </button>
              </div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center py-4"
              style={{ fontSize: "24px" }}
            >
              OR
            </div>
            <form ref={form} action="#" onSubmit={(e) => handleSendMessage(e)} className="axil-contact-form">
              <div className="form-group">
                <label>
                  Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="John Smith"
                  required
                  onChange={(e) => handletoSaveData(e)}
                />
              </div>
              <div className="form-group">
                <label>
                  Email<span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="example@mail.com"
                  required
                  onChange={(e) => handletoSaveData(e)}
                />
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Phone<span className="text-danger">*</span>
                    </label>
                    <div>
                      <PhoneInput
                        value={phone}
                        onChange={(_, data, e, formattedValue) =>
                          onPhoneChange(data, formattedValue)
                        }
                        country="in"
                        inputClass=""
                        containerClass=""
                        specialLabel=""
                      />
                    </div>
                  </div>
                  <small className="text-danger">{error}</small>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Services</label>
                    <DropdownButton
                      alignRight
                      title={valueone ? valueone : "Services"}
                      id="dropdown-menu-align-right"
                      onSelect={handleSelect}
                      value={valueone}
                      className="d-flex justify-content-between align-items-center w-100 custom_button"
                    >
                      {serviceList.map((list, i) => {
                        return (
                          <>
                            <Dropdown.Item
                              eventKey={list}
                              onClick={() => setIndexService(i)}
                            >
                              {list}
                            </Dropdown.Item>
                          </>
                        );
                      })}
                    </DropdownButton>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Start</label>
                    <DropdownButton
                      alignRight
                      title={valuetwo ? valuetwo : "Start"}
                      id="dropdown-menu-align-right"
                      onSelect={handleSelecttwo}
                      value={valuetwo}
                      className="d-flex justify-content-between align-items-center w-100 custom_button"
                    >
                      {[
                        "As Soon As Possible",
                        "Next Week",
                        "Next Month",
                        "Not Sure",
                      ].map((list) => {
                        return (
                          <>
                            <Dropdown.Item eventKey={list}>
                              {list}
                            </Dropdown.Item>
                          </>
                        );
                      })}
                    </DropdownButton>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Budget</label>
                    <DropdownButton
                      alignRight
                      title={valuethree ? valuethree : "Budget"}
                      id="dropdown-menu-align-right"
                      onSelect={handleSelectthree}
                      value={valuethree}
                      className="d-flex justify-content-between align-items-center w-100 custom_button"
                    >
                      {[
                        "Less than 1000 USD",
                        "1000 - 5000 USD",
                        "More than 5000 USD",
                      ].map((list) => {
                        return (
                          <>
                            <Dropdown.Item eventKey={list}>
                              {list}
                            </Dropdown.Item>
                          </>
                        );
                      })}
                    </DropdownButton>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Brief about the project</label>
                <textarea
                  rows={2}
                  className="form-control"
                  name="brief"
                  placeholder="Describe your project"
                  onChange={(e) => handletoSaveData(e)}
                />
              </div>
              <div className="form-group mt-4 d-flex justify-content-center">
                <button
                  type="submit"
                  className="axil-btn btn-fill-primary btn-primary"
                  name="submit-btn"
                  // onClick={(e) => handleSendMessage(e)}
                >
                  Send Message
                </button>
              </div>
              <div className="form-group">{result ? <Result /> : null}</div>
            </form>
          </div>
          <div className="col-lg-5">
            <div className="address_wrapper mb-4">
              <div style={{ height: "82px", width: "100px" }}>
                <img src="/images/Flag_of_India.svg" alt="Flag" />
              </div>
              <h6>
                WeWork, Prestige Atlanta, 80 Feet Rd, Koramangala 3 Block,
                Bengaluru, Karnataka 560034
              </h6>
              <div className="d-flex gap-2">
                <img
                  src="/images/phone2.svg"
                  alt="Phone"
                  style={{ height: "32px", width: "32px", opacity: "0.5" }}
                />
                <a
                  href="tel:+918073917014"
                  className="mb-0 cursor-pointer"
                  style={{ fontSize: "20px", color: "#999" }}
                >
                  +91 80739 17014
                </a>
              </div>
            </div>
            <div className="address_wrapper mb-4">
              <div style={{ height: "70px", width: "100px" }}>
                <img src="/images/Flag_of_Canada.svg" alt="Flag" />
              </div>
              <h6>
                1238A, The Queensway, Etobicoke, ON M8Z 1S2, Ontario, Canada
              </h6>
              <div className="d-flex gap-2">
                <img
                  src="/images/phone2.svg"
                  alt="Phone"
                  style={{ height: "32px", width: "32px", opacity: "0.5" }}
                />
                <a
                  href="tel: +1 (647) 366-6514"
                  className="mb-0 cursor-pointer"
                  style={{ fontSize: "20px", color: "#999" }}
                >
                  +1 (647) 366-6514
                </a>
              </div>
            </div>
            <div className="address_wrapper d-flex gap-2">
              <img
                src="/images/mail2.svg"
                alt="Phone"
                style={{ height: "32px", width: "32px", opacity: "0.5" }}
              />
              <a
                href="mailto:contact@nyeon.in"
                className="mb-0"
                style={{ fontSize: "20px", color: "#999" }}
              >
                contact@nyeon.in
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormOne;
