import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import CampaignCounterUp from "./CampaignCounterUp";
import { Link } from "react-router-dom";

const CampaignCounterUpOne = ({scrollToForm}) => {
  return (
    <div className="section section-padding counter_wrapper">
      <div className="container">
        <div className="row d-flex align-items-end justify-content-between">
          <div className="col-lg-8 col-sm-8 d-flex flex-column align-items-start justify-content-center">
            <h3 className="title">
              Approximately <span style={{color: "#03bebe"}}>33.7%</span> of Applicants are Accepted
            </h3>
            <p>
              Apply to Nyeon’s Free website campaign today and we will get back to you
              in 48 hours. We extend offers to approximately 33.7% of
              applicants.
            </p>
            <p>
              If your idea captures our interest, we'll respond promptly and
              connect you with a Senior WordPress Developer who will assist in
              creating your website!
            </p>
            <Link
                  to="#" onClick={scrollToForm}
                  className="axil-btn btn-fill-primary btn-large"
                >
                  Apply in 2 minutes
                </Link>
                <p className="mt-3" style={{color: "teal"}}>Hurry up! Only a Few slots are remaining for applications.</p>
            {/* <SectionTitle
              subtitle="Counter"
              title="Our Success"
              description="As a best company we had provided the best services and this are the proof of that successive works"
              textAlignment="heading-light"
              textColor=""
            /> */}
          </div>
          <div className="col-lg-4 col-sm-4">
            <CampaignCounterUp
              colSize="col-lg-12 col-12"
              layoutStyle=""
              evenTopMargin=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignCounterUpOne;
