import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ServiceProp from "../component/service/ServiceProp";
import ServiceData from "../data/service/ServiceMain.json";
import { slugify } from "../utils";
import ProjectSlider from "../component/projectsListSlider/ProjectSlider";
import Timeline from "../component/Timeline/Timeline";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Technologies from "../component/Technologies/Technologies";
import FormOne from "../component/contact/FormOne";
import AITimeline from "../component/AITimeline/AITimeline";

const allData = ServiceData;

const AIML = () => {
  const listData = [
    {
      title: "Custom Models",
      desc: "To meet your unique requirements which may not be fully satisfied by existing AI solutions, we develop a custom model.With our expertise in dataset curation and cutting-edge model development we ensure that AI solution is tailored to your needs.",
      image: "/images/custom_models.png"
    },
    {
      title: "Fine-tuning Models",
      desc: "We fine-tune pre-trained models with new dataset to cater the requirements of domain adaptation, task specific improvements, personalization, controlled generation or limited dataset.",
      image: "/images/fine_tuning_models.png"
    },
    {
      title: "Model inference optimization",
      desc: "We deliver all our AI models optimized with latest techniques like quantization, pruning, knowledge distillation, and library-based optimizations, ensuring unparalleled performance across all hardwares.",
      image: "/images/model_inference_optimization.png"
    },
    {
      title: "Scalabale deployment",
      desc: "We ensure scalability in our AI solutions to to accommodate the growing demands of data processing, handle increasing workloads, and effectively serve a larger user base without compromising performance or efficiency.",
      image: "/images/scalable_deployment.png"
    },
    {
      title: "Real-time Inference",
      desc: "Our expertise lies in developing custom-designed pipelines that efficiently execute real-time video analytics on live-feed by harnessing the power of GPUs.",
      image: "/images/real_time_inference.png"
    },
    {
      title: "In touch with Emerging trends",
      desc: "AI is a continuously changing field, and we strive to stay in touch with emerging trends in AI. We keep ourselves familiar with new libraries, models, and techniques introduced by various organizations, research groups, and publications.",
      image: "/images/in_touch_with_emerging_rends.png"
    },
  ];
  const aiTechnologies = [
    {
      title: "Frameworks",
      technologies: [
        { name: "Pytorch", logo: "images/tech/pytorch.svg" },
        { name: "Tensorflow", logo: "images/tech/tensorflow.svg" },
        { name: "OpenCV", logo: "images/tech/opencv.svg" },
        { name: "Scikit-Learn", logo: "images/tech/pytorch.svg" },
      ],
    },
    {
      title: "Optimization",
      technologies: [
        {name:"PyTorch Jit", logo:"images/tech/pytorch.svg"},
        {name:"TorchScript", logo:"images/tech/pytorch.svg"},
        {name:"Tensorflow Lite", logo:"images/tech/tensorflow.svg"},
        {name:" ONNX", logo:"images/tech/onnxai.svg"},
        {name:"OpenVINO", logo:"images/tech/openvino.png"},
        {name:"TensorRT", logo:"images/tech/tensorrt.png"},
        {name:"Core-ML", logo:"images/tech/core_ml.png"},
      ]
    },
    {
      title: "Deployment",
      technologies: [
        { name: "Gradio", logo: "images/tech/gradio.jpeg" },
        { name: "Flask/Django", logo: "images/tech/django.svg" },
        { name: "Triton server", logo: "images/tech/triton.jpeg" },
        { name: "Deepstream", logo: "images/tech/triton.jpeg" },
      ],
    },
    {
      title: "Models",
      technologies: [
        { name: "Diffusion models" },
        { name: "Transformer" },
        { name: " LLMs (GPT3.5, GPT4, LLAMA2)" },
        { name: "DALLE" },
        { name: "Midjounrey" },
      ],
    },
  ];
  return (
    <>
      <SEO title="AI & Machine Learning" />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title="AI & Machine Learning"
          subtitle="
          Wondering how AI can help you stay one step ahead of the competition?<br/>
          "
          paragraph="We assist you in building and integrating AI models into your existing infrastructure, enabling automation and scalability through our custom models, pipeline optimization, and deployment solutions.
          "
          styleClass=""
          mainThumb="/images/ai_banner.svg"
        />
        <div className="how_we_can_help_you">
          <div className="container">
            {/* <h1 className="title">How we can help you ?</h1> */}
            <div className="row">
              <div className="col-lg-6">
                <img src="images/AI_how_can_we_help.jpg" style={{mixBlendMode: "darken"}} alt="Help"/>
              </div>
              <div className="col-lg-6">
                <h4 className="text-dark">
                  Struggling with lack of in-house expertise to efficiently
                  manage and maintain AI systems in your business?
                </h4>
                {/* <h5 className="text-dark">
                  Seeking a website development partner that truly understands
                  your needs and stands out from the rest?
                </h5> */}
                <p>
                  We have got you covered with our AI & Machine learning
                  services which help you create, manage and maintain AI models
                  seamlessly into your business. Our ML engineers will work with
                  you to understand your unique requirements and suggest best
                  approach to solve your problems with AI models.
                </p>
                <p>
                  We have expertise in Chatbots, Generative AI for languages and
                  Images, Computer Vision related tasks, Video Analytics, model
                  optimization and deployment. We develop custom AI models by
                  training on the data or fine-tune on pre-trained models,
                  optimize the model for maximum efficiency during inference and
                  deploy the model with the scalable pipeline.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="how_different_wrapper">
          <div
            className="section section-padding how_wrapper"
            id="splsh-features"
          >
            <div className="container">
              <div className="section-heading heading-light-left">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <h2 className="title text-center w-100">
                      How we are different than others ?
                    </h2>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    {/* <p className="text-center w-100">
                      We will deliver a professional website that you will love,
                      whether it's for your online business, showcasing your
                      work or hobbies, promoting a social awareness campaign,
                      celebrating a special event, presenting academic work, or
                      testing a new idea you have.
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="tech_wrapper">
                <div className="row">
                {listData.map((data, index) => (
                  <div key={data.id} className="col-lg-4 col-md-6 col-12">
                    <AnimationOnScroll
                      animateIn="fadeInLeft"
                      // animateOnce={true}
                      delay={(100 * index) / 0.9}
                    >
                      <div className="services-grid digital_wrap px-5">
                        <div className="content">
                          <div className="custom_thumbnail mx-auto mb-4">
                            <img src={data.image} alt="Iconic"/>
                          </div>
                          <h5
                            className="title_us"
                              dangerouslySetInnerHTML={{ __html: data.title }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                          </h5>
                          <p className="text-center pt-2">{data.desc}</p>
                        </div>
                      </div>
                    </AnimationOnScroll>
                  </div>
                ))}
                </div>
              </div>
            </div>
            <ul className="list-unstyled shape-group-10">
              <li className="shape shape-1">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/circle-1.png"}
                  alt="Circle"
                />
              </li>
              <li className="shape shape-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/line-3.png"}
                  alt="Circle"
                />
              </li>
              <li className="shape shape-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"}
                  alt="Circle"
                />
              </li>
            </ul>
          </div>
        </div>
        <Technologies technologiesList={aiTechnologies}/>
        <AITimeline />
        {/* <ProjectSlider /> */}
        <div className="section-heading heading-light">
            <span className="subtitle" style={{color: "teal"}}>Let's Work Together</span>
            <h2 className="title text-dark">Need a successful project?</h2>
        </div>
        <FormOne/>
        {/* <CtaLayoutOne /> */}
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default AIML;
