import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";

function Campaign({scrollToForm}) {
  var slideSettings = {
    infinite: true,
    speed: 1000,
    autoplaySpeed: 2500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // variableWidth: true,
    centerMode: true,
    arrows: false,
    dots: true,
    swipeToSlide: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };
  return (
    <div>
      <div className="banner banner-style-1 custom_campaign">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="banner-content">
                {/* <AnimationOnScroll
                  animateIn="fadeInLeft"
                  animateOnce={true}
                  delay={100}
                > */}
                <h5 className="title">
                  Get a <span>FREE Website</span> and kickstart your{" "}
                  <span>Business Today</span>
                </h5>
                <h6 className="subtitle">
                  Do you have an amazing idea for a new online business but
                  worry about agencies charging you thousands of $ for the
                  website?
                </h6>
                <span className="subtitle">
                  What if we told you that you can start your online business
                  today in 2 minutes without spending a single penny?
                </span>
                <span style={{fontSize: "18px", color: "grey"}}>
                  Get your FREE website today in simple 3 steps
                  </span>
                <ul>
                  <li>1. Click on Apply</li>
                  <li>2. Fill up the form</li>
                  <li>3. We will get back to you in 48 hours</li>
                </ul>
                <span className="subtitle">
                  So don’t miss out on this opportunity, Let’s start your dream
                  business Today with Nyeon’s free website campaign.
                </span>
                <Link
                  to={process.env.PUBLIC_URL + "/campaign"} onClick={scrollToForm}
                  className="axil-btn btn-fill-primary btn-large"
                >
                  Apply in 2 minutes
                </Link>
                {/* </AnimationOnScroll> */}
                <p style={{marginTop: "21px", color: "teal"}}>Hurry up! Limited slots are available for our Exclusive Campaign.
</p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="banner-thumbnail d-flex justify-content-center">
                <img src="/images/apply-now.svg" />
              </div>
            </div>
          </div>
        </div>
        <ul className="list-unstyled shape-group-21">
          <li className="shape shape-1">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"}
              alt="Bubble"
            />
          </li>
          <li className="shape shape-2">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-38.png"}
              alt="Bubble"
            />
          </li>
          <li className="shape shape-3">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
              alt="Bubble"
            />
          </li>
          <li className="shape shape-4">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
              alt="Bubble"
            />
          </li>
          <li className="shape shape-5">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
              alt="Bubble"
            />
          </li>
          <li className="shape shape-6">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"}
              alt="Bubble"
            />
          </li>
          <li className="shape shape-7">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"}
              alt="Bubble"
            />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Campaign;
