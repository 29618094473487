import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import { DropdownButton } from "react-bootstrap";
import { countries } from "countries-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your Message has been successfully sent.
    </Alert>
  );
};

const FormTwo = () => {
  const form = useRef();

  const [result, showresult] = useState(false);
  const [stateData, setStateData] = useState({});
  const [valueone, setValueone] = useState("Other");
  const [valuetwo, setValuetwo] = useState("Other");
  const [valuecode, setValuecode] = useState("Do not know");
  const [valuethree, setValuethree] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [thankyou, setThankyou] = useState(false);
  const [error, setError] = useState("");
  const [phone, setPhone] = useState("");
  const [radioBox, setRadioBox] = useState("");
  const [success, setSuccess] = useState(false);

  const phoneFieldValues = Object.values(countries).map(
    (country) => country.phone
  );

  const handleCampaignForm = (e) => {
    setStateData({ ...stateData, [e.target.name]: e.target.value });
  };

  const handleSelect = (e) => {
    setValueone(e);
  };
  const handleSelecttwo = (e) => {
    setValuetwo(e);
  };
  const handleSelectthree = (e) => {
    setValuethree(e);
  };
  const handleSelectCode = (e) => {
    setValuecode(e);
  };
  // const handleSelect = (e) => {
  //   setValueone(e);
  // };

  const payload = {
    name: stateData.name,
    email: stateData.email,
    phone_number: phone,
    applicant_occupation: valueone || "Nothing",
    website_type: valuetwo || "Nothing",
    hosting: radioBox || "Nothing",
    number_of_pages: valuethree || "Nothing", 
    Description: stateData.contactMessage || "Nothing",
  };

  console.log("Tester", payload, valueone);

  const postCampaign = async (e) => {
    e.preventDefault();

    if (!isValidPhoneNumber(phone, countryCode)) {
      setError("Please Enter A Valid Phone Number");
      // Toast.error(`Please Enter a valid phone number`);
      return;
    }

    await axios
      .post("https://api.nyeon.in/client/campaign-form-submit", payload)
      .then((res) => {
        setThankyou(true);
        if (res.status === 201) {
          setSuccess(true);
        } else {
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err);
        setThankyou(true);
        setSuccess(false);
      });
  };

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm(
  //       "service_yj5dgzp",
  //       "template_hfduayo",
  //       form.current,
  //       "WLENsTkBytC0yvItS"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  //   form.current.reset();
  //   showresult(true);
  //   postCampaign(payload);
  // };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  const onPhoneChange = (data, formattedValue) => {
    setPhone(formattedValue);
    setCountryCode(data.countryCode);
  };

  console.log("RadioBox", radioBox);

  return (
    <form
      ref={form}
      action=""
      onSubmit={postCampaign}
      className="axil-contact-form"
    >
      {thankyou && (
        <div className="thankyou_wrap">
          {success ? (
            <>
              <h3>Thank You!</h3>
              <h6>
                We have recieved your message. Someone from our team will
                contact you soon.
              </h6>
            </>
          ) : (
            <>
              <h3>OOPS! Something Went Wrong</h3>
              <h6>
                Try to contact us with <br/> email : contact@nyeon.in <br/> call us on :
                +918073917014
              </h6>
            </>
          )}
        </div>
      )}
      <div className="row">
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>
              Name<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="name"
              onChange={(e) => handleCampaignForm(e)}
              required
            />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>
              Email<span className="text-danger">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              name="email"
              onChange={(e) => handleCampaignForm(e)}
              required
            />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group mb-2">
            <label className="mb-0">
              Phone<span className="text-danger">*</span>
            </label>
          </div>
          <div className="row">
            <div>
              <PhoneInput
                value={phone}
                onChange={(_, data, e, formattedValue) =>
                  onPhoneChange(data, formattedValue)
                }
                country="in"
                inputClass=""
                containerClass=""
                specialLabel=""
              />
            </div>
            <small className="text-danger">{error}</small>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>Applicant</label>
            <DropdownButton
              alignRight
              title={valueone ? valueone : "Applicant"}
              id="dropdown-menu-align-right"
              onSelect={handleSelect}
              value={valueone}
              className="d-flex justify-content-between align-items-center w-100 custom_button"
            >
              {["Startup", "Enterprise", "Student", "Business", "Other"].map(
                (list) => {
                  return (
                    <>
                      <Dropdown.Item eventKey={list}>{list}</Dropdown.Item>
                    </>
                  );
                }
              )}
            </DropdownButton>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>Website Type</label>
            <DropdownButton
              alignRight
              title={valuetwo ? valuetwo : "Type"}
              id="dropdown-menu-align-right"
              onSelect={handleSelecttwo}
              value={valuetwo}
              className="d-flex justify-content-between align-items-center w-100 custom_button"
            >
              {[
                "Blog Website",
                "Landing Page",
                "Business Website",
                "E-commerce store",
                "Portfolio Website",
                "Education Website",
                " Community and Forum Website",
                "Event Website ",
                "Other",
              ].map((list) => {
                return (
                  <>
                    <Dropdown.Item eventKey={list}>{list}</Dropdown.Item>
                  </>
                );
              })}
            </DropdownButton>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>Number of Pages</label>
            <DropdownButton
              alignRight
              title={valuethree ? valuethree : "Pages"}
              id="dropdown-menu-align-right"
              onSelect={handleSelectthree}
              value={valuethree}
              className="d-flex justify-content-between align-items-center w-100 custom_button"
            >
              {["1-2 Pages", "3-5 Pages", "5+ Pages", "Do not know"].map(
                (list) => {
                  return (
                    <>
                      <Dropdown.Item eventKey={list}>{list}</Dropdown.Item>
                    </>
                  );
                }
              )}
            </DropdownButton>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>Do you want hosting ?</label>
          </div>
          <div class="radio-buttons-container">
            <div class="radio-button">
              <input
                name="radio-group"
                id="radio2"
                class="radio-button__input"
                type="radio"
                onClick={() => setRadioBox("Yes")}
              />
              <label for="radio2" class="radio-button__label">
                <span class="radio-button__custom"></span>
                Yes
              </label>
            </div>
            <div class="radio-button">
              <input
                name="radio-group"
                id="radio1"
                class="radio-button__input"
                type="radio"
                onClick={() => setRadioBox("No")}
              />
              <label for="radio1" class="radio-button__label">
                <span class="radio-button__custom"></span>
                No
              </label>
            </div>
            <div class="radio-button">
              <input
                name="radio-group"
                id="radio3"
                class="radio-button__input"
                type="radio"
                onClick={() => setRadioBox("Donotknow")}
              />
              <label for="radio3" class="radio-button__label">
                <span class="radio-button__custom"></span>
                Do not Know
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group mb--40">
            <label>Describe what kind of website you want to build</label>
            <textarea
              className="form-control"
              name="contactMessage"
              rows="4"
              onChange={(e) => handleCampaignForm(e)}
            ></textarea>
          </div>
        </div>
      </div>

      <div className="form-group d-flex justify-content-center">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
          style={{ width: "fit-content", padding: "18px 62px" }}
        >
          Apply Now
        </button>
      </div>
      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
};

export default FormTwo;
