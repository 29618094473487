import React from 'react'
import PropTypes from "prop-types";


const SEO = ( {title} ) => {
    if(title === "Nyeon"){
         return(
            <>
            <meta charSet="utf-8" />
            <title>Nyeon Technologies</title>
            <meta name="description" content="Leading Tech consultancy firm providing AI & ML and web solutions" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
         )   
    }
    return (
        
        <>
            <meta charSet="utf-8" />
            <title>{title} | Nyeon Technologies</title>
            <meta name="description" content="Leading Tech consultancy firm providing AI & ML and web solutions" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;