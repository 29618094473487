import React from "react";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import FormTwo from "../component/contact/FormTwo";

const CampaignFormPage = () => {
  return (
    <>
      <SEO title="Blog Grid" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <div className="section section-padding">
          <div className="container">
            <h3 className="title text-dark text-center mb-2">
              Get your <span style={{ color: "teal" }}>FREE</span> website
            </h3>
            <h6 className="subtitle text-secondary text-center">
              Fill up this form and we will get back to you in 48 hours
            </h6>
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="contact-form-box shadow-box mb--30">
                  <FormTwo/>
                </div>
              </div>
              {/* <div className="col-xl-5 col-lg-6 offset-xl-1">
                            <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                                <h4 className="title">Phone</h4>
                                <p>Our customer care is open from Mon-Fri, 10:00 am to 6:00 pm</p>
                                <h4 className="phone-number"><a href="tel:1234567890">(123) 456 7890</a></h4>
                            </div>
                            <div className="contact-info mb--30">
                                <h4 className="title">Email</h4>
                                <p>Our support team will get back to in 48-h during standard business hours.</p>
                                <h4 className="phone-number"><a href="mailto:info@example.com">info@example.com</a></h4>
                            </div>
                        </div> */}
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
                style={{ filter: "grayscale(1) opacity(0.5)" }}
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
                style={{ filter: "grayscale(1) opacity(0.5)" }}
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>
      </main>
    </>
  );
};

export default CampaignFormPage;
