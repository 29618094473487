// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  animation: anime 0.7s linear 2s 1; }

.svg_wrapper {
  height: 270px;
  width: auto; }
  .svg_wrapper svg {
    height: 100%;
    width: 100%; }
  .svg_wrapper path {
    fill: var(--color-primary);
    stroke: var(--color-primary);
    stroke-width: 2px; }
  .svg_wrapper path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 2s linear reverse infinite; }

@keyframes dash {
  from {
    stroke-dashoffset: 10; }
  to {
    stroke-dashoffset: 990;
    fill: #333; } }

@keyframes anime {
  0% {
    height: 100vh;
    opacity: 1; }
  10% {
    height: 100vh; }
  100% {
    height: -0vh;
    opacity: 0; } }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/template/_initial_screen.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,iCAAiC,EAAA;;AAErC;EACI,aAAa;EACb,WAAW,EAAA;EAFf;IAIQ,YAAY;IACZ,WAAW,EAAA;EALnB;IAQQ,0BAA0B;IAC1B,4BAA4B;IAC5B,iBAAiB,EAAA;EAVzB;IAaQ,sBAAsB;IACtB,uBAAuB;IACvB,0CAA0C,EAAA;;AAGhD;EACE;IACI,qBAAqB,EAAA;EAEzB;IACE,sBAAsB;IACtB,UAAU,EAAA,EAAA;;AAGd;EACE;IACI,aAAa;IACb,UAAU,EAAA;EAEd;IACI,aAAa,EAAA;EAEjB;IACI,YAAY;IACZ,UAAU,EAAA,EAAA","sourcesContent":[".main {\n    height: 100vh;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: #000;\n    animation: anime 0.7s linear 2s 1;\n}\n.svg_wrapper {\n    height: 270px;\n    width: auto;\n    svg {\n        height: 100%;\n        width: 100%;\n    }\n    path {\n        fill: var(--color-primary);\n        stroke: var(--color-primary);\n        stroke-width: 2px;\n    }\n    path {\n        stroke-dasharray: 1000;\n        stroke-dashoffset: 1000;\n        animation: dash 2s linear reverse infinite;\n      }\n} \n  @keyframes dash {\n    from {\n        stroke-dashoffset: 10;\n    }\n    to {\n      stroke-dashoffset: 990;\n      fill: #333;\n    }\n  }\n  @keyframes anime {\n    0% {\n        height: 100vh;\n        opacity: 1;\n    }\n    10% {\n        height: 100vh;\n    }\n    100% {\n        height: -0vh;\n        opacity: 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
