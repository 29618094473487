import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import CampaignTestimonialPropOne from "./ProjectSliderOne";
import ProjectSliderOne from "./ProjectSliderOne";
import { Link } from "react-router-dom";

const ProjectSlider = () => {
  return (
    <div className="section section-padding testimonial_wrapper">
      <div className="container">
        <SectionTitle
          subtitle="Projects"
          title="What they say about us"
          // description="Nulla facilisi. Nullam in magna id dolor blandit rutrum eget vulputate augue sed eu leo eget risus imperdiet."
          textAlignment="heading-left"
          textColor=""
        />
        <div className="row">
          <ProjectSliderOne />
        </div>
        <div className="d-flex justify-content-center">
          <Link
            to={process.env.PUBLIC_URL + "/contact"}
            className="axil-btn btn-fill-primary btn-large"
          >
            View More
          </Link>
        </div>
      </div>
      <ul className="shape-group-4 list-unstyled">
        <li className="shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
            alt="Bubble"
          />
        </li>
      </ul>
    </div>
  );
};

export default ProjectSlider;
