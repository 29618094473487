import React from "react";
import "../Timeline/Timeline.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";

function Timeline(props) {
  return (
    <section class="design-section">
      <h6>Our Approach</h6>
      <h3>How do we work?</h3>
      {/* <p>
        We work with agility and efficiency to deliver superior quality software
        solutions to clients on time, <br /> leveraging cutting-edge technology
        and innovative problem-solving skills.
      </p> */}
      <div class="timeline">
        <div class="timeline-empty"></div>
        <div class="timeline-middle">
          <div class="timeline-circle"></div>
        </div>
          <AnimationOnScroll
                animateIn="fadeInRight"
                // animateOnce={true}
                delay={0}>
        <div class="timeline-component timeline-content">
          <div className="timeline_image">
            <img src="/images/vectoricons/requirements_gathering_with_color.png" alt="UR" />
          </div>
          <h3>Understanding Requirements</h3>
          <p>
          We start with collaborative meeting to gather and comprehend requirements. <br/><br/>
          Then create a draft proposal with features, tech specs, and estimated investment. 
          Upon approval, kick off the project.
          </p>
        </div>
          </AnimationOnScroll>
          <AnimationOnScroll
                animateIn="fadeInLeft"
                // animateOnce={true}
                delay={0}>
        <div class="timeline-component timeline-content">
        <div className="timeline_image">
            <img src="/images/vectoricons/ui_ux_with_color.png" alt="UR" />
          </div>
          <h3>Creating UI/UX and System Architecture</h3>
          <p>
          We prioritize UI/UX design by creating a detailed Figma prototype for visualizing and 
          refining project appearance and functionality based on feedback. <br/><br/>
          Simultaneously we work on designing the system architecture to ensure a clear understanding of project functionality.
          </p>
        </div>
        </AnimationOnScroll>
        <div class="timeline-middle">
          <div class="timeline-circle"></div>
        </div>
        <div class="timeline-empty"></div>

        <div class="timeline-empty"></div>

        <div class="timeline-middle">
          <div class="timeline-circle"></div>
        </div>
        <AnimationOnScroll
                animateIn="fadeInRight"
                // animateOnce={true}
                delay={0}>
        <div class=" timeline-component timeline-content">
        <div className="timeline_image">
            <img src="/images/vectoricons/frontend_backend_with_color.png" alt="UR" />
          </div>
          <h3>Developing Frontend and Backend</h3>
          <p>
          We Initiate development after finalizing UI/UX design, concurrently building Frontend 
          and Backend with your preferred technology stack. <br/><br/>
          Then we rigorously test every component to ensure a seamless experience and robust functionality 
          while maintaining ongoing communication through regular calls for progress 
          updates and feedback gathering.
          </p>
        </div>
        </AnimationOnScroll>
        <AnimationOnScroll
                animateIn="fadeInLeft"
                // animateOnce={true}
                delay={0}>
        <div class="timeline-component timeline-content">
        <div className="timeline_image">
            <img src="/images/vectoricons/deployment_with_color.png" alt="UR" />
          </div>
          <h3>Deployment</h3>
          <p>
          We proceed to the deployment phase after thoroughly meeting all requirements, 
          launching the project using reliable hosting services. <br/><br/>
          After successful deployment, remain dedicated to ongoing maintenance 
          and technical support to ensure a smooth and efficient operation
          </p>
        </div>
        </AnimationOnScroll>
        <div class="timeline-middle">
          <div class="timeline-circle"></div>
        </div>
        <div class="timeline-empty"></div>

        <div class="timeline-empty"></div>

      </div>
    </section>
  );
}

export default Timeline;
