import React from 'react';
import "../assets/scss/template/_initial_screen.scss";

function InitialScreen(props) {
    return (
        <div className='main'>
        <div className='svg_wrapper'>
        <svg version="1.1" id="Layer_1" x="0px" y="0px"
             viewBox="0 0 1096.8 500">
        <path class="st0" d="M312.8,280c-37.6,16.8-72.8,47.5-88.5,83l-25-48.9c14.7-24.5,36.6-45,63.1-59.3
            C279.4,261.9,295.3,269.5,312.8,280z"/>
        <path class="st0" d="M471.6,179l-40.3,57.3l-34.5,9.9c-14.1-11.6-30.8-21.5-49.1-30.4L471.6,179z"/>
        <path class="st1" d="M496.4,209.8l-61,117.9h-33.8c-3.5-3.7-7.1-7.4-11-11.1c-16.5-15.7-36.7-29.7-58.9-43.7
            c-48.3-30.4-193.8-83.4-193.8-83.4l8.4,17l51.7,104.8l1.4,2.8c0,0,0,0,0,0l25,48.9c-0.2,0.3-0.3,0.7-0.4,1c-1.2,2.8-2.3,5.6-3.3,8.4
            H177L69.6,160.7l34.2-35.2l195.8,78.7c38,15.7,74,35.5,105,62.8c2.6,2.3,5.2,4.6,7.6,7l19.2-37.8l-0.1,0l40.3-57.3l0.9-0.3
            L496.4,209.8z"/>
        <g>
            <path class="st2" d="M639.8,201.3v110.5h-20.2l-48.1-69.6h-0.8v69.6h-23.4V201.3h20.5l47.7,69.5h1v-69.5H639.8z M672.8,342.9
                c-2.9,0-5.6-0.2-8.2-0.7c-2.5-0.4-4.6-1-6.3-1.7l5.2-17.2c2.7,0.8,5.1,1.3,7.3,1.3c2.2,0.1,4.1-0.4,5.7-1.5
                c1.6-1.1,2.9-2.9,3.9-5.5l1.3-3.5l-29.7-85.3h24.2l17.2,60.9h0.9l17.3-60.9h24.3l-32.2,91.9c-1.5,4.5-3.7,8.3-6.3,11.7
                c-2.6,3.3-6,5.9-10,7.7C683.4,341.9,678.5,342.9,672.8,342.9z M760.8,308.2c6.2,3.5,13.5,5.2,22,5.2c6.8,0,12.9-1,18.1-3.1
                c5.3-2.1,9.5-5.1,12.8-8.9c3.3-3.8,5.6-8.3,6.6-13.4l-21.3-1.4c-0.8,2.1-1.9,3.8-3.5,5.3c-1.5,1.4-3.3,2.5-5.5,3.2
                c-2.1,0.7-4.5,1.1-7,1.1c-3.8,0-7.2-0.8-10-2.4c-2.8-1.6-5-3.9-6.5-6.9c-1.5-3-2.3-6.5-2.3-10.6v-0.1h56.4v-6.3
                c0-7.1-1-13.2-3-18.5c-2-5.3-4.7-9.7-8.3-13.2c-3.5-3.5-7.7-6.1-12.4-7.8c-4.7-1.8-9.8-2.6-15.3-2.6c-8.1,0-15.2,1.8-21.3,5.4
                c-6,3.6-10.7,8.6-14,15.1c-3.3,6.4-5,13.9-5,22.5c0,8.7,1.7,16.3,5,22.7C749.9,299.8,754.7,304.7,760.8,308.2z M764.4,262
                c0.1-3,0.9-5.7,2.3-8.1c1.5-2.7,3.6-4.8,6.3-6.4c2.7-1.6,5.8-2.4,9.3-2.4c3.3,0,6.3,0.7,8.8,2.2c2.6,1.4,4.6,3.4,6,6
                c1.4,2.6,2.2,5.5,2.2,8.8H764.4z M873.3,313.4c-8.4,0-15.6-1.8-21.7-5.3c-6.1-3.6-10.8-8.6-14.1-15c-2.6-5-4.2-10.7-4.7-17h23.4
                c0.3,2.6,0.9,5.1,1.6,7.4c1.3,3.8,3.3,6.8,5.9,9c2.6,2.2,5.9,3.2,9.7,3.2c3.8,0,7-1.1,9.6-3.2c2.6-2.2,4.5-5.2,5.8-9
                c0.8-2.3,1.4-4.7,1.7-7.4h23.5c-0.6,6.3-2.1,12-4.7,17c-3.3,6.4-8,11.4-14.1,15C888.9,311.6,881.7,313.4,873.3,313.4z M913.6,262.6
                c-0.7-5.3-2.2-10.1-4.4-14.4c-3.3-6.4-8-11.4-14.1-15c-6.1-3.6-13.3-5.4-21.7-5.4c-8.4,0-15.6,1.8-21.7,5.4
                c-6.1,3.6-10.8,8.6-14.1,15c-2.2,4.3-3.7,9.1-4.4,14.4h23.5c0.3-1.8,0.7-3.4,1.3-5c1.3-3.8,3.3-6.8,5.9-9c2.6-2.2,5.9-3.3,9.7-3.3
                c3.8,0,7,1.1,9.6,3.3c2.6,2.2,4.5,5.2,5.8,9c0.6,1.6,1,3.3,1.3,5H913.6z M929,276v35.8h23V276H929z M952.1,262.6h-23v-33.7H951
                v14.6h1c1.8-4.8,4.9-8.6,9.2-11.4c4.3-2.8,9.6-4.3,15.7-4.3c5.8,0,10.8,1.3,15.1,3.8c4.3,2.5,7.6,6.1,10,10.8
                c2.4,4.6,3.6,10.2,3.6,16.6v3.6h-23c0-4.8-1.3-8.6-3.9-11.4c-2.6-2.9-6.2-4.3-10.8-4.3c-3.1,0-5.8,0.7-8.2,2
                c-2.3,1.3-4.2,3.3-5.5,5.8C952.9,257,952.2,259.6,952.1,262.6z M982.5,276v35.8h23V276H982.5z"/>
        </g>
        </svg>
                  </div>
                  </div>
    );
}

export default InitialScreen;